import { createContext } from 'react';

// Types
export type SnackbarContextType = {
  showNotification: (message: string, type: string, duration?: number | 'dontAutohide') => void;
  hideNotification: () => void;
  status: INotificationStatus;
};

export interface INotificationStatus {
  show: boolean | undefined;
  message: string | undefined;
  variant: 'success' | 'info' | 'warning' | 'error' | '';
  duration: number | "dontAutohide"
}

const SnackbarContext = createContext<SnackbarContextType>({
  status: { show: false, message: '', variant: '', duration: 3000},
  showNotification: () => {},
  hideNotification: () => {},
});

SnackbarContext.displayName = 'SnackbarContext';

export default SnackbarContext;
