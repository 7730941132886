import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Backdrop, Box, CircularProgress, Divider, Typography } from '@mui/material';

import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import modelClassification from '@/models/classification';
import DataController from '@/lib/DataController';
import { TClassifyButtonGroupProps } from '@/@types/views/map';
import BaseInputControl from '@/controls/BaseInputControl';
import useClassify from '@/components/useClassify';

const ClassifyButtonGroup = (props: TClassifyButtonGroupProps) => {
  const { t } = useTranslation();

  const dc = new DataController(modelClassification);

  const { classify, getClassification } = useClassify();

  const { record, closeInfoCard } = props;

  const [comment, setComment] = useState<string>('');
  const [status, setStatus] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (record.gid) {
      setLoading(true);
      getClassification(record.gid as string).then((rec) => {
        if (rec) {
          setComment(rec.comment as string);
          setStatus(rec.status as number);
        }
        setLoading(false);
      });
    }
  }, []);

  const updateComment = (value: string) => {
    setComment(value);
  };

  // we will change tenant tokens to have different tokens on buttons and different meanings of classification
  // da = status 2
  // neutralno = status 1
  // ne = status -1
  // nepoznato = 0

  const handleClassify = (newStatus: number) => {
    if (record.gid) {
      classify(record.gid as string, newStatus, comment).then((success) => {
        if (success) {
          setStatus(newStatus);
          closeInfoCard();
        }
      });
    } else {
      console.log('y u no have gid??');
    }
  };

  const getStatusString = () => {
    switch (status) {
      case 0:
        return t('classify.unknown');
      case 1:
        return t('classify.neutral');
      case 2:
        return t('classify.positive');
      case -1:
        return t('classify.negative');
      default:
        return '';
    }
  };
  const getStatusIcon = () => {
    const statusColors = new Map<number, string>([
      [0, '#808080'], // Grey
      [1, '#FFFF00'], // Yellow
      [2, '#008000'], // Green
      [-1, '#FF0000'], // Red
    ]);

    const color = statusColors.get(status);

    return color ? <div style={{ border: '1px solid black', height: 14, width: 14, borderRadius: '50%', backgroundColor: color }} /> : null;
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Divider variant="fullWidth" orientation="horizontal" />
      <>
        {loading && (
          <Backdrop open sx={{ position: 'absolute', zIndex: 2, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )}
        <Box display="flex" gap={0.5} alignItems="center">
          <Typography>{t('classify.current_status')}: </Typography>
          {getStatusIcon()}
          <b>{getStatusString()}</b>
        </Box>
        <BaseInputControl field={dc.getField('comment')} formMode="form" controlMode="edit" value={comment} onChange={updateComment} />
        <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box display="flex" sx={{ gap: { xs: 1, md: 0 } }}>
            <MapCardActionButton
              onClick={() => {
                handleClassify(2);
              }}
            >
              {t('classify.positive')}
            </MapCardActionButton>
            <MapCardActionButton
              onClick={() => {
                handleClassify(1);
              }}
            >
              {t('classify.neutral')}
            </MapCardActionButton>
          </Box>
          <Box display="flex" sx={{ gap: { xs: 1, md: 0 } }}>
            <MapCardActionButton
              onClick={() => {
                handleClassify(-1);
              }}
            >
              {t('classify.negative')}
            </MapCardActionButton>
            <MapCardActionButton
              variant="outlined"
              onClick={() => {
                handleClassify(0);
              }}
            >
              {t('classify.unknown')}
            </MapCardActionButton>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default ClassifyButtonGroup;
