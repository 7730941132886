import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

// MUI Icons
import FingerprintIcon from '@mui/icons-material/Fingerprint';

export interface IMapCardHeaderID {
  id: number | string;
}

const MapCardHeaderID = (props: IMapCardHeaderID) => {
  const { t } = useTranslation();
  const { id } = props;

  return (
    <Tooltip title={t("core:buttons.copy_id")}>
      <div>
        <IconButton
          size="small"
          onClick={() => navigator.clipboard.writeText(id.toString())}
          sx={{ p: "2px" }}>
          <FingerprintIcon fontSize="small" sx={{ color: "white" }} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default MapCardHeaderID;
