/* eslint-disable */
import moment from 'moment';
import numbro from 'numbro';

// import api from "./api/api";
import WKT from "ol/format/WKT";
import WKB from "ol/format/WKB";
import { Geometry } from 'ol/geom';
import { Feature } from 'ol';
import useApi, { IApi } from '@/lib/api/useApi';
import {
  FieldAny,
  IFieldsCollection,
  IFormCollection,
  IModel,
  IViewCollection,
  IField,
  IForm,
  IView,
  IFieldOverride,
  IFieldID,
  FieldType,
  IFieldValidation,
  IPickerItems,
  PickerItemValue,
} from '../@types/models/model';
import {
  FieldValue,
  // IApiResponse,
  IApiResponseFailure,
  IApiResponseSuccess,
  IApiResponseSuccessInsert,
  Record,
} from '../@types/lib/api';
import {
  DataResponse,
  DataSingleResponse,
  DCDeleteResponse,
  DCFieldValue,
  DCInsertResponse,
  DCInsertResponseSuccess,
  DCRecord,
  DCResponseFail,
  DCUpdateResponse,
} from '@/@types/lib/dataController';
import { PickerItem } from '@/@types/controls/controls';
import { ValidatorFnName } from '@/@types/lib/appValidator';



export interface IDataController {
  apiPath: string;
  filename: string | null;
  listViews: IViewCollection;
  forms: IFormCollection;
  fields: IFieldsCollection;

  fieldId: IFieldID;

  api: IApi;

  GetData: (alternatePath?: string) => Promise<DataResponse>;
  GetDataSingle: (id: number | string, alternatePath?: string) => Promise<DataSingleResponse>;

  InsertRecord: (record: DCRecord, apiPath?: string) => Promise<DCInsertResponse>;
  UpdateRecord: (recordId: number, record: DCRecord, apiPath?: string) => Promise<DCUpdateResponse>;
  DeleteRecord: (recordId: number, apiPath?: string) => Promise<DCDeleteResponse>;

  getField: (name: string) => FieldAny;

  getFields: (fieldNames: Array<string>) => IFieldsCollection;
  getFormFields: (formId: string) => IFieldsCollection;
  getViewFields: (viewId: string) => IFieldsCollection;

  getFormFieldsNames: (formId: string) => Array<string>;
  getHiddenViewFieldsNames: (viewId: string) => Array<string>;

  getHiddenFormFieldsNames: (viewId: string) => Array<string>;

  getSource: () => string;

  getValidator: (formId: string) => ValidatorFnName | null;

  path_combine: (path: string, recordId: number) => string;
}

class DataController implements IDataController {
  apiPath: string;

  filename: string | null;

  listViews: IViewCollection;

  forms: IFormCollection;

  fields: IFieldsCollection;

  fieldId: IFieldID;

  api: IApi;

  constructor(model: IModel, customPath?: string) {
    this.apiPath = customPath || model.apiPath;
    this.filename = model.exportFileName ? model.exportFileName : null;
    this.listViews = model.listViews;
    this.forms = model.forms;
    this.fields = model.fields;

    this.fieldId = this.fields.find((attr) => attr.idattr === true) as IFieldID;

    this.api = useApi();
  }


  checkIfParamsInUrl(url: string) {
    const a = url.indexOf('{');
    const b = url.indexOf('}');
    if (a > 0 && b > 0 && b > a) {
      return true;
    } else {
      return false;
    }
  }

  substituteParamsInUrl(url: string, rec: DCRecord) {
    const a = url.indexOf('{');
    const b = url.indexOf('}');
    const key = url.substring(a, b);
    const val = rec[key] as string;
    const searchValue = '{' + key + '}'
    if (val) {
      return url.replace(searchValue, val);
    } else {
      return url;
    }
  }

  determinePath(customUrl: string | null, modelUrl: string, rec?: DCRecord) {
    if (customUrl) {
      if (this.checkIfParamsInUrl(customUrl) && rec) {
        return this.substituteParamsInUrl(customUrl, rec);
      } else {
        return customUrl;
      }
    } else {
      if (this.checkIfParamsInUrl(modelUrl) && rec) {
        return this.substituteParamsInUrl(modelUrl, rec);
      } else {
        return modelUrl;
      }
    }

  }

  path_combine(path: string, recordId: number | string): string {
    const pathEndsWithSlash = path.charAt(path.length - 1) === '/';
    return path + (pathEndsWithSlash ? '' : '/') + recordId;
  }

  // CustomGetAction(url: string) : Promise<IApiResponse> {
  //   return this.api.Call(url, "get");
  // }

  // CustomPutAction(url: string, data: Record): Promise<IApiResponse> {
  //   const putData = this.prepareSendingData(data);
  //   return this.api.Call(url, "put", putData);
  // }

  // CustomPostAction(url: string, data: Record | Array<Record>, multi = false): Promise<IApiResponse> {
  //   let postData = null;

  //   if (multi && Array.isArray(data)) {
  //     postData = data.map(d => this.prepareSendingData(d));
  //   } else {
  //     postData = this.prepareSendingData(data as Record);
  //   }

  //   return this.api.Call(url, "post", postData);
  // }

  // CustomPatchAction(url: string, data: Record): Promise<IApiResponse> {
  //   const patchData = this.prepareSendingData(data);
  //   return this.api.Call(url, "patch", patchData);
  // }

  GetData(customPath: string | null = null): Promise<DataResponse> {
    const path = customPath !== null ? customPath : this.apiPath;
    return this.api.get(path).then((resp) => {
      if (Array.isArray(resp.data)) {
        const data = resp.data.map((record) => this.prepareReturnData(record));
        const { success } = resp;
        return { success, data };
      }
      return {
        success: resp.success,
        data: this.prepareReturnData(resp.data as Record),
      };
    });
  }

  GetDataSingle(id: number | string, customPath: string | null = null): Promise<DataSingleResponse> {
    const path = customPath !== null ? customPath : this.path_combine(this.apiPath, id);

    return this.api.get(path).then((resp) => {
      if (resp.success) {
        const returnData = this.prepareReturnData(resp.data as Record);
        return Promise.resolve({ success: true, data: returnData });
      }
      return Promise.reject({ sucess: false, error: resp.error });
    });
  }

  InsertRecord(record: DCRecord, customPath: string | null = null): Promise<DCInsertResponse> {
    const insertData = this.prepareSendingData(record);
    const path = customPath !== null ? customPath : this.apiPath;
    return this.api
      .post(path, insertData)
      .then((resp) => {
        if (resp && resp.success) {
          const response = resp as IApiResponseSuccessInsert;
          const newId = response.data.id;
          if (newId) {
            return Promise.resolve({
              success: true,
              data: response.data,
              id: newId,
            } as DCInsertResponseSuccess);
          }
          return Promise.resolve({
            success: true,
            data: response.data,
            id: 0,
          } as DCInsertResponseSuccess);
        }
        const response = resp as IApiResponseFailure;
        return Promise.reject({
          success: false,
          // Privremeno rješenje
          errmsg: (response.error as any).message.detail.errmsg as string,
          errcode: (response.error as any).message.detail.errcode as number,
          errsubmsg: (response.error as any).message.detail.errsubmsg as string,
          errsubcode: (response.error as any).message.detail.errsubcode as number,
        } as DCResponseFail);
      })
      .catch((err) =>
        Promise.reject({
          success: false,
          errmsg: err.errmsg,
          errcode: err.errcode,
          errsubmsg: err.errsubmsg,
          errsubcode: err.errsubcode,
        } as DCResponseFail)
      );
  }

  UpdateRecord(recordId: number, record: DCRecord, customPath: string | null = null): Promise<DCUpdateResponse> {
    if (!this.fieldId) {
      this.debugLog(
        'Attempt to update record without id attribute in the model!'
      );
      return Promise.reject('Unknown ID field!');
    }

    if (!recordId) {
      this.debugLog('Attempt to update record withhout recordId!');
      return Promise.reject('Unknown record Id!');
    }

    const updateData = this.prepareSendingData(record);
    const currPath = customPath !== null ? customPath : this.apiPath;
    const path = this.path_combine(currPath, recordId);
    return this.api
      .put(path, updateData)
      .then((response) => {
        if (response.success) {
          return Promise.resolve({ success: true, data: response.data });
        }
        return Promise.reject({ success: false, error: response.error });
      })
      .catch((err) => Promise.reject(err));
  }

  DeleteRecord(recordId: number, customPath: string | null = null, deleteData: Record = {}): Promise<DCDeleteResponse> {
    if (!this.fieldId) {
      this.debugLog(
        'Attempt to delete record without id attribute in the model!'
      );
      return Promise.reject('Unknown ID field!');
    }

    if (!recordId) {
      this.debugLog('Attempt to delete record withhout recordId!');
      return Promise.reject('Unknown record Id!');
    }
    const sourceKey = this.fieldId.source as keyof Record;
    deleteData[sourceKey] = recordId;
    const currPath = customPath !== null ? customPath : this.apiPath;
    const path = this.path_combine(currPath, recordId);
    return this.api
      .remove(path, deleteData)
      .then((response) => {
        if (response && response.success) {
          // const response = resp as IApiResponseSuccessDelete;
          return Promise.resolve({ success: true, data: response.data });
        }
        // const response = resp as IApiResponseFailure;
        return Promise.reject({ success: false, error: response.error });
      })
      .catch((err) => Promise.reject(err));
  }

  debugLog(msg: string) {
    console.warn(msg);
  }

  prepareReturnData(record: Record): DCRecord {
    const dcr: DCRecord = {};

    // in case the record is empty
    if (!record) {
      return dcr;
    }

    // we pass everything (filter keys also!)
    Object.keys(record).forEach((k) => {
      const field = this.getField(k);
      const key = k as keyof Record;
      const dkey = k as keyof DCRecord;
      if (field) {
        switch (field.type) {
          case 'date':
          case 'datetime':
            if (record[key]) {
              dcr[dkey] = moment.utc(record[key] as string);
            }
            break;
          case 'document':
            dcr[dkey] = record[key] as DCRecord;
            break;
          case 'picker':
            if (record[key] !== null) {
              if (field.formatting) {
                switch (field.formatting) {
                  case 'dhm': {
                    const secondsInDay = 24 * 60 * 60;
                    const time = moment()
                      .startOf('day')
                      .seconds((record[key] as number) * 60 * 60);

                    const timeFormatted =
                      (record[key] as number) < secondsInDay
                        ? `${time.format('H')} h ${time.format('m')} min`
                        : `${Math.floor(
                          (record[key] as number) / secondsInDay
                        )} d ${time.format('H')} h ${time.format('m')} min`;

                    dcr[dkey] = {
                      value: record[key] as PickerItemValue,
                      label: timeFormatted,
                    } as PickerItem;
                    break;
                  }
                  case 'km': {
                    const kmFormatted: PickerItem = {
                      value: record[key] as PickerItemValue,
                      label: `${Math.ceil(
                        record[key] as number
                      ).toString()} km`,
                    };
                    dcr[dkey] = kmFormatted;
                    break;
                  }
                  default:
                    break;
                }
              } else {
                dcr[dkey] = record[key] as PickerItemValue | PickerItemValue[];
              }
            }
            break;
          case 'radio':
            if (record[key] !== null) {
              dcr[dkey] = record[key] as PickerItemValue;
            }
            break;
          case 'checkbox':
            if (record[key] !== null) {
              dcr[dkey] = record[key] as PickerItemValue | PickerItemValue[];
            }
            break;
          case "wkt":
            if (record[key] !== null) {
              const format = new WKT()
              const feature = format.readFeature(record[key], {
                dataProjection: field.sourceProjection,
                featureProjection: field.targetProjection
              });
              feature.setId(record.id as string);
              // @ts-ignore
              dcr[dkey] = feature;
            }
            break;
          case "wkb":
            if (record[key] !== null) {
              const format = new WKB();
              // const wkb = (record[key] as string).substring(2);
              const feature = format.readFeature(record[key] as string) as Feature<Geometry>;
              feature.setId(record.id as string);
              dcr[dkey] = record[key] as string;
              if (key === "wkb") {
                // @ts-ignore
                dcr.geom = feature;
              }
            }
            break;
          default:
            dcr[dkey] = record[key] as DCFieldValue;
            break;
        }
      }
    });

    return dcr;
  }

  prepareSendingData(record: DCRecord): Record {
    const data: Record = { ...record };

    const subModelKey = 'value';

    // we pass everything (filter keys also!)
    Object.keys(data).forEach((k) => {
      const field = this.getField(k);
      const key = k as keyof Record;
      if (field) {
        switch (field.type) {
          case 'date':
            data[key] = data[key]
              ? moment(data[key] as string).format('YYYY-MM-DD')
              : null;
            break;
          case 'datetime':
            data[key] = data[key]
              ? moment.utc(data[key] as string).toISOString(true)
              : null;
            break;
          case 'picker':
          case 'radio': {
            const dk = data[key];
            if (
              typeof dk === 'object' &&
              dk !== null &&
              dk.hasOwnProperty(subModelKey)
            ) {
              data[key] = (dk as Record)[subModelKey];
            } else if (field.multi) {
              if (Array.isArray(data[key])) {
                data[key] = (data[key] as Array<Record>).map((d) =>
                  d[subModelKey] ? d[subModelKey] : d
                );
              }
            }
            break;
          }
          case 'checkbox': {
            const dd = data[key];
            if (Array.isArray(dd) && dd.length > 0) {
              const dataArray: Array<FieldValue> = [];
              let dataInt = 0;
              if (field.flags) {
                dd.forEach((d) => {
                  dataInt += d[subModelKey] as number;
                });
                data[key] = dataInt;
              } else {
                dd.forEach((d) => {
                  if (d.hasOwnProperty(subModelKey)) {
                    dataArray.push(d[subModelKey]);
                  } else {
                    dataArray.push(d);
                  }
                });
                data[key] = dataArray;
              }
            }
            break;
          }
          case 'numeric':
            if (data[key] !== null && typeof data[key] === 'string') {
              data[key] = parseFloat((data[key] as string).replace(',', '.'));
            }
            break;
          case 'currency':
            if (data[key] !== null) {
              const parsedValue = numbro(data[key]);
              numbro.setLanguage('en-US');
              data[key] = numbro.unformat(parsedValue.format());
              numbro.setLanguage('hr-HR');
            }
            break;
          case 'images':
            if (field.object) {
              data[key] = (data[key] as Array<Record>)[0];
            }
            break;
          case 'document':
            data[key] = data[key] as Array<Record>;
            break;
          case "wkt":
            if (data[key] !== null) {
              const format = new WKT()

              const featureWKT = format.writeFeature(data[key] as Feature<Geometry>);

              data[key] = data[key] ? featureWKT : null;
            }
            break;
          case "array":
            if (typeof data[key] === 'string') {
              const splitData = (data[key] as string).split(',');

              if (splitData.length === 0 || (splitData.length === 1 && (splitData[0] === "" || !splitData[0]))) {
                data[key] = null;
              } else {
                const arrayType = this.determineArrayType(splitData);
                const newData = splitData.map((x) => {
                  if (arrayType === "integer") {
                    return parseInt(x);
                  } if (arrayType === "float") {
                    return parseFloat(x);
                  } return x;
                })

                data[key] = newData;
              }
            } else if (Array.isArray(data[key])) {
              data[key] = data[key];
            } else {
              data[key] = null;
            }
            break;
          default:
            break;
        }
      }
    });

    // ensure we send id
    // if (this.fieldId) {
    //   data[this.fieldId.source] = record[this.fieldId.source]; //ensure we have recordId
    // }

    return data;
  }

  getField(name: string): FieldAny {
    const field = this.fields.find((f) => f.source === name);

    return field ? JSON.parse(JSON.stringify(field)) : null; // clone
  }

  getFields(fieldNames: Array<string>): IFieldsCollection {
    const fields = fieldNames
      ? fieldNames.map((name) => this.fields.find((f) => f.source === name))
      : [];
    return JSON.parse(JSON.stringify(fields)); // clone
  }

  // Helpers

  determineArrayType(arr: string[]): string {
    let hasString = false;
    let hasFloat = false;

    for (const element of arr) {
      if (isNaN(parseFloat(element))) {
        // If it's not a valid float, consider it a string
        hasString = true;
      } else if (Number(element) % 1 !== 0) {
        // If it's a float, but not an integer
        hasFloat = true;
      }
    }

    if (hasString) {
      return "string";
    } if (hasFloat) {
      return "float";
    }
    return "integer";

  }

  overrideFieldConfigurations(
    baseFields: IFieldsCollection,
    overrideFields: Array<string | IFieldOverride>
  ): IFieldsCollection {
    overrideFields.forEach((f) => {
      if (typeof f === 'object') {
        Object.keys(f)
          .filter((x) => x !== 'source')
          .forEach((key) => {
            const baseField: IField = baseFields.find(
              (x: IField) => x.source === f.source
            ) as IField;
            const fIndexKey = key as keyof IFieldOverride;
            const baseFieldIndexKey = key as keyof IField;
            if (baseField) {
              if (f[fIndexKey] !== null && f[fIndexKey] !== undefined) {
                switch (fIndexKey) {
                  case 'title':
                    baseField.title = f[fIndexKey] as string;
                    break;
                  case 'ttoken':
                    baseField.ttoken = f[fIndexKey] as string;
                    break;
                  case 'type':
                    baseField.type = f[fIndexKey] as FieldType;
                    break;
                  case 'readonly':
                    baseField.readonly = f[fIndexKey] as boolean;
                    break;
                  case 'validation':
                    baseField.validation = f[fIndexKey] as IFieldValidation;
                    break;
                  case 'items':
                    baseField.items = f[fIndexKey] as IPickerItems;
                  case 'filter':
                    baseField.filter = f[fIndexKey] as boolean;
                    break;
                  default:
                    break;
                }

                // baseField[fIndexKey] = f[fIndexKey];
              } else {
                delete baseField[baseFieldIndexKey];
              }
            }
          });
      }
    });
    return baseFields;
  }

  getSource(): string {
    return this.apiPath !== null &&
      this.apiPath !== undefined &&
      this.apiPath.length
      ? this.apiPath
      : 'undefined_source';
  }

  // View

  getView(viewId: string): IView | null {
    if (viewId !== undefined && this.listViews.hasOwnProperty(viewId)) {
      return this.listViews[viewId];
    }
    if (this.listViews.hasOwnProperty('default')) {
      return this.listViews.default;
    }
    this.logError(this.listViews, 'default');
    return null;
  }

  getViewFieldsNames(viewId: string): Array<string> {
    const view = this.getView(viewId);
    if (view && view.fields && Array.isArray(view.fields)) {
      return view.fields.map((x) => (typeof x === 'object' ? x.source : x));
    }
    this.logError(view, 'fields');
    return [];
  }

  getViewFields(viewId: string): IFieldsCollection {
    const view = this.getView(viewId);
    if (view === null) {
      this.logError(view, '');
      return [];
    }

    const fieldNames = this.getViewFieldsNames(viewId);
    let baseFields = this.getFields(fieldNames);

    baseFields = this.overrideFieldConfigurations(baseFields, view.fields);

    return baseFields;
  }

  getHiddenViewFieldsNames(viewId: string): Array<string> {
    const view = this.getView(viewId);
    if (
      view !== null &&
      view.hasOwnProperty('hidden') &&
      Array.isArray(view.hidden)
    ) {
      return ['id'].concat(view.hidden);
    }
    return ['id'];
  }

  // Form

  getForm(formId: string): IForm | null {
    if (this.forms === undefined) {
      return null;
    }
    if (formId !== undefined && this.forms[formId]) {
      return this.forms[formId];
    }
    if (this.forms.default) {
      return this.forms.default;
    }
    this.logError(this.forms, 'default');
    return null;
  }

  getFormFieldsNames(formId: string): Array<string> {
    const form = this.getForm(formId);
    if (form && form.fields && Array.isArray(form.fields)) {
      return form.fields.map((x) => (typeof x === 'object' ? x.source : x));
    }
    this.logError(form, 'fields');
    return [];
  }

  getHiddenFormFieldsNames(formId: string): Array<string> {
    const form = this.getForm(formId);

    if (form && form.hidden) {
      return form.hidden;
    } return [];
  }

  getFormFields(formId: string): IFieldsCollection {
    const form = this.getForm(formId);
    if (form === null) {
      this.logError(form, '');
      return [] as IFieldsCollection;
    }
    const fieldNames = this.getFormFieldsNames(formId);
    let baseFields = this.getFields(fieldNames);

    baseFields = this.overrideFieldConfigurations(baseFields, form.fields);

    return baseFields;
  }

  // Validator

  getValidator(formId: string) {
    const form = this.getForm(formId);
    if (form && form.validator) {
      return form.validator;
    }
    // this.logError(form, "validator");
    return null;
  }

  // Error handling

  /**
   * @description Error logger for objects
   * @name logError
   * @params
   *   obj: object
   *   key: string (optional)
   *   type: string (optional)
   * @returnVal
   *  void
   */
  logError(obj: object | null, key = '') {
    const indexKey = key as keyof object;
    if (obj === null || obj === undefined) {
      console.error('Object', obj, 'is null or undefined!');
    } else if (!obj.hasOwnProperty(key)) {
      console.error('Object', obj, "doesn't contain the key:", key);
    } else if (obj[indexKey] === null || obj[indexKey] === undefined) {
      console.error(
        'Object',
        obj,
        'value under key:',
        key,
        'is',
        obj[indexKey]
      );
    } else {
      console.error('Unknown error!', obj, key);
    }
  }

  hexStringToBuffer(hexString: string): ArrayBuffer {
    const bytes = new Uint8Array(
      (hexString.match(/.{1,2}/g) || []).map(byte => parseInt(byte, 16))
    );
    return bytes.buffer;
  }
}

export default DataController;
