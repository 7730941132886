import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import SnackbarContext, { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';

// Custom
import DataController from '@/lib/DataController';
import modelClassification from '@/models/classification';
import { DCRecord } from '@/@types/lib/dataController';

export interface IClassify {
  classify: (gid: string, status: number, comment: string) => Promise<boolean>;
  getClassification: (gid: string) => Promise<DCRecord | null>;
}

// Hook
function useClassify(): IClassify {
  const dc = new DataController(modelClassification);

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const { t } = useTranslation();

  const classify = (gid: string, status: number, comment: string): Promise<boolean> =>
    dc
      .InsertRecord({ entity_gid: gid, status, comment })
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification(t('notifications.success'), 'success');
          return true;
        }
        snackbarContext.showNotification(t('notifications.fail'), 'error');
        return false;
      })
      .catch((error) => {
        snackbarContext.showNotification(t('notifications.fail'), 'error');
        console.error('Classification error:', error);
        return false;
      });

  const getClassification = async (gid: string): Promise<DCRecord | null> => {
    try {
      const resp = await dc.GetDataSingle(gid);
      console.log(resp)
      if (resp.success) {
        if (Object.keys(resp.data as DCRecord).length) { // because {} is returned as resp.data when no classification found
          return resp.data as DCRecord;
        }
      }
      return null;
    } catch (error) {
      console.error('Error while fetching classification');
      return null;
    }
  };

  return {
    classify,
    getClassification,
  };
}

export default useClassify;
