/* eslint-disable */
import * as React from 'react';
import { Fragment, FunctionComponent, useContext } from 'react';

//Material UI
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

//Custom components
import SnackbarContext from './SnackbarContext';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarContainer = () => {
  const snackbarContext = useContext(SnackbarContext);

  const variant = snackbarContext && snackbarContext.status.variant ? snackbarContext.status.variant : 'info';
  const message = snackbarContext ? snackbarContext.status.message : '';

  const handleClose = (event?: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    if (snackbarContext && snackbarContext.hideNotification) {
      snackbarContext.hideNotification();
    }
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        key={'snackglobal'}
        open={snackbarContext ? snackbarContext.status.show : false}
        onClose={handleClose}
        autoHideDuration={snackbarContext.status.duration === 'dontAutohide' ? null : snackbarContext.status.duration}
      >
        <Alert severity={variant} onClose={handleClose} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SnackbarContainer;
