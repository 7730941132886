/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, FunctionComponent, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Provider,
  TreeItem2Root,
  useTreeItem2,
} from '@mui/x-tree-view';

// MUI Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';

// Openlayers
import OlLayerGroup from 'ol/layer/Group';
import OlBaseLayer from 'ol/layer/Base';

// Custom components
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';
import { findLayer, flattenLayers } from '@/lib/olHelpers';
import TimeSeriesSlider from '@/ui/Slider/TimeSeriesSlider';
import OpacitySlider from '@/ui/Slider/OpacitySlider';
import LayerTreeItemChip from './LayerTreeItemChip';

// Types
import { ILayerTreeItem } from '@/@types/components/Map/Controls/Custom/LayerTree';

const LOCAL_STORAGE_KEY = 'opacity_layer_values';

const getStoredOpacityValue = (mapId: number, layerId: string): number | null => {
  try {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData && typeof parsedData === 'object' && parsedData[mapId]) {
        return parsedData[mapId][layerId] ?? null;
      }
    }
  } catch (error) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    // console.error('Error parsing localStorage data:', error);
  }
  return null;
};

const saveOpacityValueToLocalStorage = (mapId: number, layerId: string, opacityValue: number) => {
  try {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    const parsedData = storedData ? JSON.parse(storedData) : {};

    if (typeof parsedData !== 'object') throw new Error('Stored data is not an object');

    parsedData[mapId] = {
      ...parsedData[mapId],
      [layerId]: opacityValue,
    };

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedData));
  } catch (error) {
    // console.error('Error saving opacity value to localStorage:', error);
  }
};

const LayerTreeItem: FunctionComponent<ILayerTreeItem> = (props) => {
  const { t } = useTranslation();

  const mapContext = useContext(MapContext) as MapContextType;
  const { mapId } = mapContext;

  const { layer, onNodeSelect, currentZoomLevel } = props;

  const visibility = mapContext.getLayerVisibility();
  const layerExtent = layer.getExtent();

  const id = layer.get('id');
  const title = layer.get('title');
  const visible = !(Object.hasOwn(visibility, id) && visibility[id] === false);

  const hasOpacitySlider = layer.get('has_opacity_slider');

  const [opacityValue, setOpacityValue] = useState<number>(100);

  const show = title && title.length > 0;
  if (!show) {
    return null;
  }

  const isGroupLayer = layer instanceof OlLayerGroup;

  const directChildLayers = isGroupLayer ? layer.getLayers().getArray() : null;
  const children = directChildLayers?.map((l) => (
    <LayerTreeItem key={`lti-${l.get('id')}`} layer={l} onNodeSelect={onNodeSelect} currentZoomLevel={currentZoomLevel} />
  ));
  const allChildLayers = isGroupLayer ? flattenLayers(directChildLayers, 5) : null;

  const isVisible = (l: OlBaseLayer) => {
    const layerId = l.get('id');
    return !(Object.hasOwn(visibility, layerId) && visibility[layerId] === false);
  };

  const childsVisible = allChildLayers ? allChildLayers.filter((x) => isVisible(x) === true && !(x instanceof OlLayerGroup)).length : null;
  const childsHidden = allChildLayers ? allChildLayers.filter((x) => isVisible(x) === false && !(x instanceof OlLayerGroup)).length : null;

  const groupChecked = childsVisible ? childsVisible > 0 : false;
  const groupIndeterminate = childsVisible && childsHidden ? childsVisible > 0 && childsHidden > 0 : false;

  const checked = isGroupLayer ? (groupIndeterminate ? true : groupChecked) : visible;

  const layersTime = mapContext.getLayerTime();
  const time = layersTime && layersTime[id] ? layersTime[id] : undefined;

  const timeseries = layer.get('timeseries');
  const hasSlider = !!timeseries;

  const zoomable = layer.get('zoomable');

  const label = time && hasSlider ? `${t(title)} (${time})` : t(title);

  const visibleFromZoomLevel = layer.get('visible_min_zoom');
  const visibleToZoomLevel = layer.get('visible_max_zoom');

  // const hasVisibilityLimits = visibleFromZoomLevel || visibleToZoomLevel;

  const lowBounderyOk = currentZoomLevel && visibleFromZoomLevel ? currentZoomLevel >= visibleFromZoomLevel : true;
  const upperBoundaryOk = currentZoomLevel && visibleToZoomLevel ? currentZoomLevel <= visibleToZoomLevel : true;
  const dataVisible = lowBounderyOk && upperBoundaryOk;

  // if (hasVisibilityLimits) {
  //   console.log(id, dataVisible);
  // }

  const ticks = timeseries ? timeseries.length - 1 : 0;
  const hasTimeSeriesSlider = timeseries && timeseries.length > 0;

  const handleValueLabel = (x: number) => {
    if (timeseries && timeseries.length > x) {
      return timeseries[x];
    }
    return '?';
  };

  const handleChangeCommitted = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    event.stopPropagation();
    const newTime = handleValueLabel(value as number);
    if (id && newTime) {
      mapContext.setLayerTime(id, newTime);
    }
  };

  const handleChangeOpacity = (event: Event, value: number | Array<number>) => {
    event.stopPropagation();
    if (mapContext && mapContext.map && typeof value === 'number') {
      const mapLayer = findLayer(mapContext.map, id);
      mapLayer?.setOpacity(value / 100);
      setOpacityValue(value);

      if (mapId && id) {
        saveOpacityValueToLocalStorage(mapId, id, value);
      }
    }
  };

  const handleZoomToLayer = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.stopPropagation();
    if (mapContext && mapContext.map && layerExtent) {
      const view = mapContext.map.getView();
      view.fit(layerExtent, { padding: [20, 20, 20, 220], duration: 1000 });
    }
  };

  const { getRootProps, getContentProps, getIconContainerProps, getCheckboxProps, getLabelProps, getGroupTransitionProps, status } =
    useTreeItem2({ id, itemId: id, children, label });

  useEffect(() => {
    if (mapId && id) {
      const storedOpacity = getStoredOpacityValue(mapId, id);
      if (storedOpacity !== null && mapContext && mapContext.map) {
        setOpacityValue(storedOpacity);
        const mapLayer = findLayer(mapContext.map, id);
        mapLayer?.setOpacity(storedOpacity / 100);
      }
    }
  }, [mapId, id, mapContext.map]);

  return (
    // @ts-ignore i am not smart enough to fix this one unfortunately
    <TreeItem2Provider itemId={id}>
      <TreeItem2Root {...getRootProps()}>
        <TreeItem2Content
          sx={{ gap: 0, p: 0 }}
          {...getContentProps()}
          onClick={(evt) => {
            onNodeSelect(evt, id);
          }}
        >
          <TreeItem2Checkbox
            {...getCheckboxProps()}
            sx={{ padding: '9px' }}
            color="primary"
            checked={checked}
            indeterminate={groupIndeterminate}
          />
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ width: '100%', fontWeight: isGroupLayer ? "bold" : "normal" }} component="div" {...getLabelProps()}>
              {t(label)}
              {isGroupLayer ? <LayerTreeItemChip allChildLayers={allChildLayers} /> : null}
            </Typography>
          </Box>
          {hasTimeSeriesSlider && (
            <TimeSeriesSlider
              ticks={ticks}
              checked={checked}
              handleValueLabel={handleValueLabel}
              handleChangeCommited={handleChangeCommitted}
            />
          )}
          <Box display="flex" maxWidth="7rem">
            {hasOpacitySlider && <OpacitySlider value={opacityValue} checked={checked} handleChange={handleChangeOpacity} />}
            {dataVisible && zoomable && (
              <IconButton onClick={handleZoomToLayer} color="primary" disabled={!layerExtent} edge="start" size="small">
                <LocationOnIcon />
              </IconButton>
            )}
            {!dataVisible && (
              <Tooltip title={t('tooltips.zoom_level_helper_text') as string}>
                <IconButton disableTouchRipple disableRipple size="small">
                  <VisibilityOffTwoToneIcon sx={{ color: 'grey' }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {status.expandable && (
            <TreeItem2IconContainer
              sx={{ marginRight: '8px' }}
              {...getIconContainerProps()}
              onClick={(evt) => {
                evt.stopPropagation();
                getContentProps().onClick(evt);
              }}
            >
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>
          )}
        </TreeItem2Content>
        {directChildLayers && <TreeItem2GroupTransition {...getGroupTransitionProps()}>{children}</TreeItem2GroupTransition>}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
};

export default LayerTreeItem;
