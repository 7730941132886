import React from 'react';

// MUI
import { useTheme } from '@mui/material';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { Icon } from '@mui/material';

// Types
export interface IMapCardAvatar extends AvatarProps {
  icon: string;
  ariaLabel?: string;
}

const MapCardAvatar = (props: IMapCardAvatar) => {
  const { icon, ariaLabel } = props;

  const theme = useTheme();

  return (
    <Avatar
      aria-label={ariaLabel}
      sx={{
        border: 2,
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main
      }}
    >
      <Icon>
        {icon}
      </Icon>
    </Avatar>
  );
};

export default MapCardAvatar;
