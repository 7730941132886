enum EnumLayerFunctionality {
    dkp_show_posjednici_vlasnici = 1,
    dkp_show_posjednici = 2,
    cjenovni_blok_custom_actions = 3,
    odvoz_location_button = 4,
    kuce_za_odmor_button = 5,
    toggle_dof = 6,
    google_maps_navigate_button = 7,
    objekti_ko_photodoc = 8,
    grobna_mjesta_show_ukopani = 9,
    toggle_objekti = 10,
    classify = 11
  }

export default EnumLayerFunctionality;