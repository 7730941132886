import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBase, Icon, Tooltip, useTheme } from '@mui/material';

import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

type CesiumButtonProps = {
  icon: string;
  tooltipToken: string | { active: string; notActive: string };
  onClick: () => void;
  active?: boolean;
  marginTop?: number | string;
  disabled?: boolean;
};

const CesiumButton = (props: CesiumButtonProps) => {
  const { icon, tooltipToken, onClick, active = false, disabled = false, marginTop = 0 } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  const handleclick = () => {
    if (disabled === undefined || !disabled) {
      onClick();
    }
  };

  return (
    <Tooltip
      title={typeof tooltipToken === 'string' ? t(tooltipToken) : active ? t(tooltipToken.active) : t(tooltipToken.notActive)}
      placement="left"
    >
      <ButtonBase
        disableRipple={disabled}
        disableTouchRipple={disabled}
        sx={{
          mt: marginTop,
          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundColor: active ? theme.palette.primary.dark : theme.palette.primary.main,
          borderRadius: '10%',
          color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
          zIndex: 999,
          ':hover': {
            backgroundColor: !disabled ? theme.palette.primary.dark : theme.palette.primary.main,
          },
          cursor: !disabled ? 'pointer' : 'not-allowed',
        }}
      >
        <Icon fontSize="large" onClick={handleclick}>
          {icon}
        </Icon>
      </ButtonBase>
    </Tooltip>
  );
};

export default CesiumButton;
