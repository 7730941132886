import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from 'react-i18next';

// MUI
import Chip from "@mui/material/Chip";

// Custom components
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';

// Openlayers
import OlLayerGroup from 'ol/layer/Group';
import OlBaseLayer from 'ol/layer/Base';

export interface ILayerTreeItemChip {
  allChildLayers: OlBaseLayer[] | null
}

const LayerTreeItemChip: FunctionComponent<ILayerTreeItemChip> = (props) => {

  const mapContext = useContext(MapContext) as MapContextType;

  const { t } = useTranslation();

  const { allChildLayers } = props;

  const visibility = mapContext.getLayerVisibility();

  const isVisible = (l: OlBaseLayer) => {
    const layerId = l.get('id');
    return !(Object.hasOwn(visibility, layerId) && visibility[layerId] === false);
  };

  const childsVisible = allChildLayers ? allChildLayers.filter((x) => isVisible(x) === true && !(x instanceof OlLayerGroup)).length : 0;

  const hasAllHidden = childsVisible === 0 ? true : false;
  const allLayersCount = allChildLayers ? allChildLayers.length : 0;

  const label = `${childsVisible} ${t("common.od")} ${allLayersCount}`;

  return (
    <Chip size="small" label={label} color={hasAllHidden ? "default" : "primary"} sx={{ml: 1}} />
  );
}

export default LayerTreeItemChip;