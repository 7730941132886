// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cesiumContainer {
  height: 100%;
}

.cesium-button:hover {
  box-shadow: unset;
}

.cesium-viewer-fullscreenContainer{
  bottom: 5px;
  right: 5px; 
  border-radius: 14%;
}

.cesium-selection-wrapper > svg {
  scale: 0.37;
  stroke: black;
  fill: rgb(97, 205, 87);
}

.cesium-infoBox {
  display: none !important;
}

.cesium-widget-credits {
  display: none !important;
}

#cesium-draw-toolbar {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  right: 5px;
  top: 350px;
  justify-content: center;
  align-items: end;
}`, "",{"version":3,"sources":["webpack://./src/styles/cesium.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":["#cesiumContainer {\n  height: 100%;\n}\n\n.cesium-button:hover {\n  box-shadow: unset;\n}\n\n.cesium-viewer-fullscreenContainer{\n  bottom: 5px;\n  right: 5px; \n  border-radius: 14%;\n}\n\n.cesium-selection-wrapper > svg {\n  scale: 0.37;\n  stroke: black;\n  fill: rgb(97, 205, 87);\n}\n\n.cesium-infoBox {\n  display: none !important;\n}\n\n.cesium-widget-credits {\n  display: none !important;\n}\n\n#cesium-draw-toolbar {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n  position: absolute;\n  right: 5px;\n  top: 350px;\n  justify-content: center;\n  align-items: end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
