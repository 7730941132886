import { DCRecord } from "@/@types/lib/dataController";

function exportCSV(csvContent: any, fileName = 'export.csv') {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}


function actualExportCSV(data: DCRecord[], fileName = 'export.csv') {
  if (!data || data.length === 0) {
      console.warn('No data to export');
      return;
  }

  const keys = Object.keys(data[0]); // Extract column names
  const csvRows = [keys.join(',')]; // Add header row



  const dataRows = data.map(record =>
      keys.map(key => {
          let value = record[key];
          
          if (typeof value === 'string' && value.includes(',')) {
              value = `"${value}"`; // Escape commas in strings
          }
          return value;
      }).join(',')
  );

  csvRows.push(...dataRows);
  
  const csvContent = csvRows.join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export {actualExportCSV};

export default exportCSV