import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const Classification: IModel = {
  title: 'Classification',
  apiPath: 'core/classification',
  forms: {
    default: {
      fields: ['comment'],
    },
  },
  listViews: {
    default: {
      fields: ['gid', 'files'],
    },
  },
  fields: [
    coreFields.id,
    coreFields.gid,
    {
      title: 'Komentar',
      source: 'comment',
      ttoken: 'common.comment',
      type: 'text',
      validation: {
        required: false,
      },
      filter: true,
      hideHelperText: true
    },
    {
      title: 'Status',
      source: 'status',
      ttoken: 'common.status',
      type: 'picker',
      filter: true,
      items: {
        labels: ['classify.positive', 'classify.negative', 'classify.unknown'], // TODO: fetch from endpoint
        values: [1, -1, 0],
      },
    },
  ],
};

export default Classification;
