// React
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

// Cesium
import { Color } from 'cesium';

// MUI
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { CloudDownload, Remove } from '@mui/icons-material';

// Custom
import { DCRecord } from '@/@types/lib/dataController';
import { actualExportCSV } from '@/lib/exportCSV';

export type ClickedPoint = {
  id: string;
  position: { x: number; y: number; z: number }; // IN HTRS
  category: number;
  color: Color;
};

export type IClickedPointTalbeProps = {
  points: ClickedPoint[];
  removePoint: (id: string, index: number) => void;
};

const getTableColorStyle: (color: string) => CSSProperties = (color: string) => ({
  borderRadius: '50%',
  backgroundColor: color,
  border: '1px solid black',
  width: '15px',
  height: '15px',
});

const posToString = (position: { x: number; y: number; z: number }) =>
  `${position.x.toFixed(2)}, ${position.y.toFixed(2)}, ${position.z.toFixed(2)}`;

const ClickedPointTable = (props: IClickedPointTalbeProps) => {
  const { points, removePoint } = props;

  const { t } = useTranslation();

  const handleExportToExcel = () => {
    const records: DCRecord[] = points.map((point) => ({
      id: point.id,
      x: point.position.x.toFixed(2).replace('.',','),
      y: point.position.y.toFixed(2).replace('.',','),
      z: point.position.z.toFixed(2).replace('.',','),
    }));

    actualExportCSV(
      records,
      'clicked-points',
    );
  };

  return (
    <TableContainer component={Paper} sx={{ width: 'fit-content', maxHeight: 225, maxWidth: { xs: 350, sm: 400, md: 500, lg: 550 } }}>
      <Table aria-label="click table" stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: '2px 8px' }} size="small" variant="head" align="left">
              ID
            </TableCell>
            <TableCell sx={{ padding: '2px 8px' }} variant="head">
              {t('common.pointxyz')}
            </TableCell>
            <TableCell sx={{ padding: '2px 8px' }} size="small" variant="head">
              {t('common.category')}
            </TableCell>
            <TableCell sx={{ padding: '2px 8px' }} size="small" variant="head" align="right">
              <Tooltip onClick={handleExportToExcel} title={t('buttons.export')}>
                <IconButton>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {points.map((point, index) => (
            <TableRow
              key={`table-row-point${point.position.x}-${point.position.y}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ padding: '2px 8px' }} align="left" size="small">
                {point.id}
              </TableCell>
              <TableCell sx={{ padding: '2px 8px' }} align="left" size="small">
                <span>{posToString(point.position)}</span>
              </TableCell>
              <TableCell sx={{ padding: '2px 8px' }} align="center" size="small">
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div style={getTableColorStyle(point.color.toCssHexString())} />
                </div>
              </TableCell>
              <TableCell sx={{ padding: '2px 8px' }} align="center" size="small">
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Tooltip title={t('buttons.remove')}>
                    <IconButton size="small" onClick={() => removePoint(point.id, index)}>
                      <Remove />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClickedPointTable;
