/* eslint-disable */
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Custom Components
import SnackbarContext, {
  INotificationStatus,
} from '@/context/SnackbarContext/SnackbarContext';

const SnackbarProvider = ({ children }: { children: ReactElement }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<INotificationStatus>({
    show: false,
    message: '',
    variant: '',
    duration: 3000
  });

  const showNotification = (
    message: string,
    type: 'success' | 'info' | 'warning' | 'error' | string,
    duration: number | 'dontAutohide' = 3000
  ): void => {
    let msg = t('notifications.action');
    switch (message) {
      case 'update':
        msg = t('notifications.update');
        break;
      case 'insert':
        msg = t('notifications.insert');
        break;
      case 'delete':
        msg = t('notifications.delete');
        break;
      case 'copy':
        msg = t('notifications.copy');
        break;
      default:
        if (message && message.length > 0) {
          msg = t(message);
        }
        break;
    }

    let newVariant = status.variant;
    switch (type) {
      case 'success':
      case 'info':
      case 'error':
      case 'warning':
        newVariant = type;
        break;
      default:
        break;
    }

    setStatus({ show: true, message: msg, variant: newVariant, duration});
  };

  const hideNotification = () => {
    setStatus(prev => ({ ...prev, show: false }));
  };

  const providerValue = {
    status,
    showNotification,
    hideNotification,
  };

  return (
    <SnackbarContext.Provider value={providerValue}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
